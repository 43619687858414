@unocss;
html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  scroll-behavior: smooth;
  background-color: #F5F5F5;
  
}
html::-webkit-scrollbar{
  display: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.product_images{
  border:border-box;
  box-shadow:-2px -2px -2px white;
}


.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-2	{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-4	{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.line-clamp-none {
  -webkit-line-clamp: unset;
}

.bg-sky {
  background-color: rgb(14 165 233);
}

.focus\:ring-yellow-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(13 148 136 / var(--tw-ring-opacity));
}

.focus\:ring-teal-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity));
}

.search .ant-input-group-addon {
    background-color: black;
}

ol,ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button{
  background: none;
  border: none;
}

a, a:active, a:hover, a:visited{
  color: inherit;
  text-decoration: none;
}

button, a, div, p {
    font-style: inherit;
    font-size: inherit;
    font-weight: inherit;
}

h1,h2,h3,h4,h5,h6,p {
    margin: 0;
    font-style: inherit;
    font-size: inherit;
    font-weight: inherit;
}

.ant-collapse-header {
    padding: 12px 0px !important;
}

.ant-collapse-content-box {
    padding: 0px !important;
}